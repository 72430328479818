import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Preferences} from "@capacitor/preferences";
import {Router} from "@angular/router";
import {AuthenticationService} from "@app/services";
import {LoadingController} from "@ionic/angular";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private loadingController: LoadingController,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // @ts-ignore
        return from(this.handle(request, next))
    }

    async handle(request: HttpRequest<any>, next: HttpHandler) {

        const token = await Preferences.get({key: 'token'});

        const authReq = request.clone({
            withCredentials: false,
            setHeaders: {
                "Authorization":  token.value ? "Token " + token.value : '',
                'content-type': 'application/json',
                'Accept': 'application/json'
            }
        })


        return next.handle(authReq).pipe(
            catchError(async error => {
                if (error.status === 401 || error.status === 403) {
                    const loading = await this.loadingController.create();
                    await loading.dismiss();
                    await this.authService.logoutExpired();
                }

                throw error;
            })

        ).toPromise();
    }



}
