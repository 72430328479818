export interface SignupUserForm {
    email: string,
    password: string,
    confirmPassword: string
}

export interface SignupUserResponse {
    key: string
}

export interface VerifyEmail {
    key: string
}

export class SignupUserConverter {
    email: string;
    username: string;
    password1: string;
    password2: string;

    constructor(
       form:SignupUserForm
    ) {
        this.email = form.email;
        this.username = form.email;
        this.password1 = form.password;
        this.password2 = form.confirmPassword;
    }
}
