import { Injectable } from '@angular/core';
import {AlertController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})

export class ErrorService {
  constructor(
      private alertController: AlertController,
  ) {}

  async showPopup(subject: string, msg:string) {
    const alert = await this.alertController.create({
      header: subject,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();
  }

}
