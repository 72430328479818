import { Injectable } from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { Preferences} from "@capacitor/preferences";

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanLoad {
  constructor(private router: Router) { }

  async canLoad(): Promise<boolean> {
    // const hasSeenIntro = await Preferences.get({key: 'onboarding'});
    //
    // console.log(hasSeenIntro)
    // if (hasSeenIntro && (hasSeenIntro.value === 'true')) {
    //   this.router.navigateByUrl('/tabs', { replaceUrl: true });
    // } else {
      return true;
    // }
  }
}
