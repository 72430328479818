import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Tag } from "./tags.service";

export interface Entry {
  entry_id: number;
  author: number;
  tags: Tag[];
  title: string;
  content: string;
  last_modified: string;
  starred: boolean;
  deleted: boolean;
  archived: boolean;
}

export interface EntryNew {
  tags: Array<{ name: string }>;
  title: string;
  content: string;
}

export interface EntriesResponse {
  count: number;
  next: string;
  previous: string;
  results: Entry[];
}

@Injectable({
  providedIn: "root",
})
export class EntriesService {
  constructor(private http: HttpClient) {}

  getEntries(): Observable<EntriesResponse> {
    return this.http.get<EntriesResponse>(`${environment.apiUrl}/entries/`);
  }

  getEntry(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/entries/${id}/`);
  }

  patchEntry(entry, id?:number): Observable<any> {
    console.log(entry)
    return this.http.patch(
      `${environment.apiUrl}/entries/${id ? id : entry.track.track_id}/`,
      entry
    );
  }

  patchSingleEntry(entryId: string | number, key, value): Observable<any> {
    const obj = {};
    obj[key] = value;
    console.log(obj);
    return this.http.patch(`${environment.apiUrl}/entries/${entryId}/`, obj);
  }

  addEntry(entry): Observable<any> {
    return this.http.post(`${environment.apiUrl}/entries/`, entry);
  }
}
