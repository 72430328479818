import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from '@app/services';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(
      private authService: AuthenticationService,
      private router: Router
  ) { }


  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
        filter(val => val !== null),
        take(1),
        map(isAuthenticated => {
          if (isAuthenticated) {
            this.router.navigateByUrl('/tabs', { replaceUrl: true });
          } else {
            // this.router.navigateByUrl('/welcome', { replaceUrl: true });
            return true;
          }
        })
    )
  }
}
