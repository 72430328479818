export interface SignInUserForm {
    email: string,
    username: string,
    password: string,
    confirmPassword: string
}

export interface UserDataResponse {
    pk: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    status?: number;
}

export interface UserDataFacebookResponse {
    id: string;
    name: string;
    picture: {
        data: {
            height: number;
            is_silhouette: boolean;
            url: string;
            width: number
        }
    },
    birthday: string,
    email: string
}


export interface FacebookAuthResponse {
    authResponse: {
        accessToken: string;
        userID: string;
        expiresIn: number;
        signedRequest: string;
        graphDomain: string;
        data_access_expiration_time: number;
    },
    status: string
}

export interface SignInUserResponse {
    key: string
}

export class SignInUserConverter {
    username: string;
    password: string;

    constructor(
       form:SignInUserForm
    ) {
        this.username = form.username;
        this.password = form.password;
    }
}
