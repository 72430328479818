import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';
import {
    FacebookAuthResponse,
    SignInUserConverter,
    SignInUserForm,
    SignInUserResponse,
    SignupUserConverter,
    SignupUserForm,
    SignupUserResponse
} from "@app/classes";
import {environment} from "../../environments/environment";
import {GoogleAuth, User} from "@codetrix-studio/capacitor-google-auth";
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import {WhatsNextResponse} from "./home.service";
import {UserDataFacebookResponse} from "../classes/signin";
import {Router} from "@angular/router";

const FACEBOOK_PERMISSIONS = ['email', 'user_birthday'];

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    token = '';

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.loadToken();
    }

    async loadToken() {
        const token = await Preferences.get({ key: 'token' });
        if (token && token.value) {
            this.token = token.value;
            this.isAuthenticated.next(true);
        } else {
            this.isAuthenticated.next(false);
        }
    }

    resetPassword(email): Observable<any> {
        return this.http.post(environment.apiUrl + '/auth/password/reset/', email)
    }

    login(credentials: SignInUserForm): Observable<any> {
        return this.http.post(environment.apiUrl + '/auth/login/', new SignInUserConverter(credentials)).pipe(
            map((data: SignInUserResponse) => data.key),
            switchMap(token => {
                return from(Preferences.set({key: 'token', value: token}));
            }),
            tap(_ => {
                this.isAuthenticated.next(true);
            })
        )
    }

    register(credentials: SignupUserForm): Observable<any> {
        return this.http.post(environment.apiUrl + '/auth/register/', new SignupUserConverter(credentials)).pipe(
            map((data: SignupUserResponse) => data.key),
            switchMap(token => {
                return from(Preferences.set({key: 'token', value: token}));
            }),
            tap(_ => {
                this.isAuthenticated.next(true);
            })
        )
    }

    googleLogin(): Observable<any> {
        return new Observable((observer) => {
            from(GoogleAuth.signIn()).subscribe((user:User) => {
                console.log(user)

                this.http.post(environment.apiUrl + '/auth/social/google/', {
                    access_token: user.authentication.accessToken,
                    code:user.serverAuthCode,
                    id_token:user.id,
                }).subscribe((data:SignInUserResponse) => {
                    if(data.key){
                        Preferences.set({key: 'token', value: data.key})
                        this.isAuthenticated.next(true);
                        observer.next(data)
                        observer.complete();
                    }
                }, error => {
                    observer.error(error)
                    observer.complete();
                })

            }, error => {
                console.log(error)
            })
        });
    }

    async loadUserFbata(userId, token):Promise<UserDataFacebookResponse> {
        return new Promise(resolve => {
            this.http.get(`https://graph.facebook.com/${userId}?fields=id,name,picture.width(720),birthday,email&access_token=${token}`)
                .subscribe((res: UserDataFacebookResponse) => {
                    console.log(res)
                    resolve(res)
                });
        })
    }

    fbLogin(): Observable<any> {
        return new Observable((observer) => {
            from(FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })).subscribe(async (resp: FacebookLoginResponse) => {



                this.http.post(environment.apiUrl + '/auth/social/facebook/', {
                    access_token: resp.accessToken.token,
                    fields: "id,name,picture.width(720),birthday,email,first_name,last_name"
                }).subscribe((data:SignInUserResponse) => {
                    if(data.key){
                        Preferences.set({key: 'token', value: data.key})
                        this.isAuthenticated.next(true);
                        observer.next(data)
                        observer.complete();
                    }
                }, error => {
                    observer.error(error)
                    observer.complete();
                })


            }, error => {
                console.log(error)
            })
        });
    }

    logout(): Promise<void> {
        return new Promise(resolve => {
            this.http.post(environment.apiUrl + '/auth/logout/', {}).subscribe(data => {
                this.isAuthenticated.next(false);
                Preferences.remove({key: 'email'});
                Preferences.remove({key: 'token'});
                Preferences.remove({key: 'userId'});
                resolve()
            })
        })
    }

    logoutExpired(): Promise<void> {
        return new Promise(resolve => {
            this.isAuthenticated.next(false);
            Preferences.remove({key: 'token'});
            Preferences.remove({key: 'userId'});
            this.router.navigateByUrl('/welcome',{replaceUrl: true});
            resolve()
        })
    }
}
