import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard, IntroGuard, AutoLoginGuard} from "@app/guards";
import {Not_foundModule} from "./pages/not_found/not_found.module";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'restore-password',
    loadChildren: () => import('./pages/restore-password/restore-password.module').then( m => m.RestorePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'account-reset-password/:uid/:token',
    loadChildren: () => import('./pages/account-reset-password/account-reset-password.module').then( m => m.AccountResetPasswordModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  // {
  //   path: 'onboarding',
  //   loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule),
  //   // canLoad: [IntroGuard, AuthGuard]
  // },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'exploration/:id',
    loadChildren: () => import('./pages/exploration/exploration.module').then( m => m.ExplorationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'entry-summary/:id',
    loadChildren: () => import('./pages/entry/entry.module').then( m => m.EntryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'entry/:id',
    loadChildren: () => import('./pages/exploration-edit/exploration-edit.module').then( m => m.ExplorationEditModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'new-entry',
    loadChildren: () => import('./pages/new-entry/new-entry.module').then( m => m.NewEntryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'explore-select',
    loadChildren: () => import('./pages/explore-select/explore-select.module').then( m => m.ExploreSelectModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not_found/not_found.module').then( m => m.Not_foundModule),
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then( m => m.UnsubscribeModule),
  },
  {
    path: 'email-verification',
    loadChildren: () => import('./pages/email-verification/email-verification.module').then(m => m.EmailVerificationModule),
  },
  {
    path: 'email-verification/account-confirm-email/:id',
    loadChildren: () => import('./pages/email-confirmed/email-confirmed.module').then(m => m.EmailConfirmedModule),
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'not-found',
    // pathMatch: 'full'
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
