import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../environments/environment";
import {from, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {
  ChangePasswordConverter,
  ChangePasswordForm,
  SetNewPasswordConverter,
  SetNewPasswordForm,
  UserDataResponse
} from "@app/classes";
import {Preferences} from "@capacitor/preferences";
import {Entry} from "./entries.service";

export interface Profile {
  id: number;
  core_values: string[];
  core_desire: string;
  user: number;
}

export interface BillingPortalResponse {
  billing_portal: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) {}


  getUserData(): Observable<any> {
    return this.http.get(environment.apiUrl + '/auth/user/').pipe(
        map((data: UserDataResponse) => data.pk),
        switchMap(id => {
          return from(Preferences.set({key: 'userId', value: String(id)}));
        })
    )
  }

  getProfileData(): Observable<any> {
    return this.http.get(environment.apiUrl + '/profiles/');
  }

  getBillingPortalURL(): Observable<any> {
    return this.http.get(environment.apiUrl + '/auth/stripe_portal/');
  }

  updateProfile(profile: Profile): Observable<any>{
    delete profile['id']
    delete profile['core_values']
    return this.http.post(environment.apiUrl + '/profiles/', profile)
  }

  changePassword(changePasswordForm: ChangePasswordForm): Observable<any>{
    return this.http.post(environment.apiUrl + '/auth/password/change/', new ChangePasswordConverter(changePasswordForm))
  }

  setNewPassword(setNewPasswordForm: SetNewPasswordForm): Observable<any>{
    return this.http.post(environment.apiUrl + '/auth/password/reset/confirm/', new SetNewPasswordConverter(setNewPasswordForm))
  }

  verifyEmail(token: string): Observable<any>{
    return this.http.post(environment.apiUrl + '/auth/register/verify-email/', {
      key: token
    })
  }

  resendVerifyEmail(email: string): Observable<any>{
    return this.http.post(environment.apiUrl + '/auth/register/resend-email/', {
      email
    })
  }


}
