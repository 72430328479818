import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../environments/environment";
import {from, Observable} from "rxjs";

export interface Methods {
  id: string;
  name: string;
}
export interface Frequencies {
  id: string;
  name: string;
}

export interface Settings {
  id?: number;
  display_name?: string;
  subscription?: string;
  contact_wait?: string;
  contact_frequency?: string;
  contact_method?: string;
  notifications_reminder?: boolean;
  notifications_product_updates?: boolean;
  user?: number;
}

export interface Waits {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class SettingsService {

  constructor(private http: HttpClient) {}

  getSettings(): Observable<any>{
    return this.http.get(environment.apiUrl + '/settings/')
  }

  setSettings(settings: Settings): Observable<any>{
    return this.http.post(environment.apiUrl + '/settings/', settings)
  }

  getMethods(): Observable<any>{
    return this.http.get(environment.apiUrl + '/settings/methods/')
  }

  getWaits(): Observable<any>{
    return this.http.get(environment.apiUrl + '/settings/waits/')
  }

  getFrequencies(): Observable<any>{
    return this.http.get(environment.apiUrl + '/settings/frequencies/')
  }

  public  methodFormat(id: string): string{
    if(id === 'Em'){
      return 'Email'
    } else if (id === 'Txt'){
      return 'Text'
    } else if (id === 'App'){
      return 'In app'
    }
  }

  public  waitsFormat(id: string): string{
    if(id === 'Dft'){
      return 'Default'
    } else if (id === 'Mnt'){
      return 'Month'
    } else if (id === 'Wk'){
      return 'Week'
    } else if (id === 'Day'){
      return 'Day'
    } else if (id === 'Nvr'){
      return 'Never'
    }
  }

  public  frequenciesFormat(id: string): string{
    if(id === 'Dft'){
      return 'Default'
    } else if (id === 'Mn'){
      return 'Monthly'
    } else if (id === 'Wk'){
      return 'Weekly'
    } else if (id === 'Day'){
      return 'Daily'
    } else if (id === 'Nvr'){
      return 'Never'
    }
  }

  public methodToUpperCase(method: string): string{
    return method.toLocaleUpperCase()
  }

}
