import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../environments/environment";
import {SignupUserConverter, SignupUserResponse} from "@app/classes";
import {map, switchMap, tap} from "rxjs/operators";
import {from, Observable} from "rxjs";
import {Preferences} from "@capacitor/preferences";

export interface Tag {
  id: number;
  name: string;
  color: string;
}

export interface TagsResponse {
  count: number;
  next: any;
  previous: any;
  results: Tag[];
}



@Injectable({
  providedIn: 'root'
})

export class TagsService {

  constructor(private http: HttpClient) {}

  getTags(): Observable<any>{
    return this.http.get(environment.apiUrl + '/tags/favorites/')
  }


  addTag(tag: Tag): Observable<any>{
    return this.http.post(environment.apiUrl + '/tags/favorites/add/', tag)
  }

  addTagToEntry(tag: Tag, id:number): Observable<any>{
    return this.http.post(environment.apiUrl + '/entries/' + id + '/tag/', tag)
  }

  untag(tag: Tag, id:number): Observable<any>{
    return this.http.post(environment.apiUrl + '/entries/' + id + '/untag/', tag)
  }

  removeTagFromVaforite(tag: Tag): Observable<any>{
    return this.http.post(environment.apiUrl + '/tags/favorites/remove/', tag)
  }





}
