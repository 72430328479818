export interface SetNewPasswordForm {
    newPassword: string,
    confirmPassword: string
    uid: number
    token: string
}


export class SetNewPasswordConverter {
    new_password1: string;
    new_password2: string;
    uid: number;
    token: string;

    constructor(
       form:SetNewPasswordForm
    ) {
        this.new_password1 = form.newPassword;
        this.new_password2 = form.confirmPassword;
        this.uid = form.uid;
        this.token = form.token;
    }
}
