import {Component, OnInit} from '@angular/core';
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {Preferences} from "@capacitor/preferences";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor() {
    // GoogleAuth.init();
    GoogleAuth.initialize({
      clientId: '188512014856-eq01j43f7og879dd5u9c6gb76247kjb3.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    })
  }


  async ngOnInit() {

  }
}
