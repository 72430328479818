import {Methods} from "@app/services";

export class MethodsConverter {
    id: string;
    name: string;

    constructor(
        obj: Methods
    ) {
        this.id = obj.id.toLocaleUpperCase();
        this.name = obj.name;
    }
}
