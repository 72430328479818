import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SignupUserConverter, SignupUserResponse } from "@app/classes";
import { map, switchMap, tap } from "rxjs/operators";
import { from, Observable, of } from "rxjs";
import { Preferences } from "@capacitor/preferences";

export interface Explorations {
  author: { id: number; name: string; website: boolean; description: string };
  description: string;
  exploration_id: number;
  name: string;
  permission: boolean;
  premium: boolean;
  tags: any;
}

export interface Tracks {
  premium: boolean;
  completed_at: any;
  created_at: string;
  deleted: boolean;
  dismissed: boolean;
  last_modified_at: string;
  progress: any;
  starred: boolean;
  target_date: any;
  track: {
    track_id: number;
    name: string;
    category: string;
    premium: boolean;
    image: string;
    permission: boolean;
  };
  user: 27;
  user_track_id: 142;
}

export interface Track {
  category: string;
  image: string;
  name: string;
  permission: boolean;
  premium: boolean;
  track_id: number;
}

export interface ExplorationsResponse {
  count: number;
  next: string;
  previous: string;
  results: Explorations[];
}

export interface TracksResponse {
  count: number;
  next: string;
  previous: string;
  results: Tracks[];
}

export interface TrackResponse {
  count: number;
  next: string;
  previous: string;
  results: Track[];
}

@Injectable({
  providedIn: "root",
})
export class ExplorationsService {
  constructor(private http: HttpClient) {}

  getExplorations(): Observable<ExplorationsResponse> {
    return this.http.get<ExplorationsResponse>(
      `${environment.apiUrl}/explorations/`
    );
  }

  getExploration(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/explorations/${id}`).pipe(
      map((resp: any) => {
        return {
          ...resp,
          pages: resp.pages.map((page) => {
            return {
              ...page,
              elements: page.elements,
            };
          }),
        };
      })
    );
  }

  saveTrackProgress(body): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/track/progress/${body.id}`,
      body
    );
  }

  saveExplorationProgress(body): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/${body.exploration_id}/progress/`,
      body
    );
  }

  getBrowseTracks(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/tracks/browse/`);
  }

  getTracks(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/tracks/`);
  }

  getTrack(id): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/tracks/${id}/`)
      .pipe(tap(console.log));
  }

  startNewTrack(track_id): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/tracks/${track_id}/start/`)
      .pipe(tap(console.log));
  }

  getMyTracks(nextUrl?: string | null): Observable<TracksResponse> {
    if (nextUrl) {
      return this.http.get<TracksResponse>(nextUrl);
    } else {
      return this.http.get<TracksResponse>(`${environment.apiUrl}/mytracks/`);
    }
  }

  getMyTrack(id): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/mytracks/${id}/`)
      .pipe(tap(console.log));
  }

  deleteMyTrack(body, id): Observable<any> {
    return this.http.put(`${environment.apiUrl}/mytracks/${id}/`, body);
  }

  updateMyTrack(body, id): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/mytracks/${id}/`, body);
  }

  completeMyTrack(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/mytracks/${id}/complete/`);
  }

  categorySearch(category?): Observable<TracksResponse> {
    return this.http.post<TracksResponse>(
      `${environment.apiUrl}/tracks/search/`,
      {
        category,
      }
    );
  }

  featuredSearch(search?): Observable<TrackResponse> {
    return this.http
      .post<TrackResponse>(`${environment.apiUrl}/tracks/search/`, {
        search,
      })
      .pipe(tap(console.log));
  }
}
