export interface ChangePasswordForm {
    password: string,
    newPassword: string,
    confirmPassword: string
}


export class ChangePasswordConverter {
    old_password: string;
    new_password1: string;
    new_password2: string;

    constructor(
       form:ChangePasswordForm
    ) {
        this.old_password = form.password;
        this.new_password1 = form.newPassword;
        this.new_password2 = form.confirmPassword;
    }
}
