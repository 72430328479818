import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "@app/services";
import { filter, map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canLoad(route: Route): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          if (
            route.path === "login" ||
            route.path === "register" ||
            route.path === "restore-password"
          ) {
            this.router.navigateByUrl("/tabs/home");
          } else {
            return true;
          }
        } else {
          if (
            route.path === "login" ||
            route.path === "register" ||
            route.path === "restore-password"
          ) {
            return true;
          } else {
            this.router.navigateByUrl("/welcome");
            return false;
          }
        }
      })
    );
  }
}
