import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { from, Observable } from "rxjs";

export interface WhatsNextResponse {
  exploration_id: number;
  name: string;
  description: string;
}

export interface RecommendationsResponse {
  id: number;
  author: number;
  tags: Array<{ name: string }>;
  title: string;
  content: string;
  last_modified: string;
  starred: boolean;
  deleted: boolean;
  archived: boolean;
}

export interface StatsResponse {
  id: number;
  name: string;
  description: string;
}

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private http: HttpClient) {}

  getWhatsNext(): Observable<any> {
    return this.http.get(environment.apiUrl + "/home/whats_next/");
  }

  getRecommendations(): Observable<any> {
    return this.http.get(environment.apiUrl + "/home/recommendations/");
  }

  getStats(): Observable<any> {
    return this.http.get(environment.apiUrl + "/home/stats/");
  }
}
